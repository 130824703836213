/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { format } from 'date-fns';
import Helper from './Helper';
import { isEmpty, normalizeArrayToObject, orderBy } from '../../utils/object';
import configENV from '../../config';
import { countryISOAlfa2Code } from '../constants/Countries';

const { PERSIST_KEY, AUTH_LOGIN_URL, SHOP_THE_SITE_KEY } = configENV;

const openPreviousModal = (state) => {
    const { modalHistory } = state;
    if (modalHistory.length === 0) {
        return { modal: state.modal, modalHistory: [] };
    }
    const modal = modalHistory.pop();
    return { modal, modalHistory };
};

const openModal = (
    state,
    title = '',
    content = {},
    customClass = '',
    layout = '',
    config = '',
    isMainModal = false,
) => {
    const { modal, modalHistory } = state;
    if (modal.open) {
        modalHistory.push({ ...modal }); // push a copy of modal
    }
    modal.open = true;
    modal.title = title;
    modal.content = content;
    modal.customClass = customClass;
    modal.layout = layout;
    modal.config = config;
    modal.isMainModal = isMainModal;

    return { modal, modalHistory };
};

const closeAllModals = (state) => {
    const { modal } = state;
    modal.open = false;
    modal.title = '';
    modal.content = {};
    modal.customClass = '';
    modal.layout = '';
    modal.config = '';
    return { modal, modalHistory: [] };
};

const closeModal = (state, action) => {
    const { modal, modalHistory } = state;
    const { closeAll } = action ? action.data : false;
    if (modalHistory.length && !closeAll) {
        const modalState = openPreviousModal(state);
        return {
            modal: modalState.modal,
            modalHistory: modalState.modalHistory,
        };
        // eslint-disable-next-line no-else-return
    } else {
        modal.open = false;
        modal.title = '';
        modal.content = {};
        modal.customClass = '';
        modal.layout = '';
        modal.config = '';
        return {
            modal,
            modalHistory: [],
            recipient: {},
        };
    }
};

const setBusyState = (state) => Helper.setState(state, { isReadyState: false });

const setBusyFetching = (state) => Helper.setState(state, { isFetchingModal: true });

const showErrorDialog = (state, action) => {
    const newState = { ...state };
    const { error } = action;
    const networkErrorMessage = ['<p>', '<span class="bold">Oops!</span>', '&nbsp;', 'There is an issue with the network connection. Please check your setting and try again', '</p>'].join('');
    let errorMessage = error.message || error.errorMessage;
    try {
        const errorCode = error.response.status;
        if (errorCode > 400 && errorCode !== 401 && errorCode < 500) {
            // errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', 'It seems like your session expried. Please try to login again.', '</p>'].join('');
            errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', "Something went wrong and we couldn't process your request. Please try again later.", '</p>'].join('');
        } else if (errorCode === 401) {
            if (typeof newState.pageData === 'object') {
                newState.pageData.url = '/auth/login';
            }
        } else if (errorCode >= 500) {
            errorMessage = networkErrorMessage;
        }
        // eslint-disable-next-line no-empty
    } catch (ex) { }
    if (errorMessage.match(/Network Error/)) {
        errorMessage = networkErrorMessage;
    }
    // Clear sessionStorage
    sessionStorage.removeItem(PERSIST_KEY);
    // Open error message
    return openModal(
        newState,
        '',
        errorMessage,
        'dialog',
        'HTMLContent',
        'ErrorOutline',
    );
};

const setReadyFetching = (state, action) => {
    const { error } = action;
    if (error && error.message) {
        const modalState = showErrorDialog(state, action);
        const data = {
            isFetchingModal: false,
            modal: modalState.modal,
            modalHistory: modalState.modalHistory,
        };
        return Helper.setState(state, data);
    }
    return Helper.setState(state, { isFetchingModal: false });
};

const setReadyState = (state, action) => {
    const { error, type } = action;
    const { toggleState } = state;
    if (error && error.message) {
        const data = {};
        if (/status code 401/.test(error.message)) {
            // TODO: need to change to use pageData: { url: AUTH_LOGIN_URL }
            data.callBack = { url: AUTH_LOGIN_URL };
        } else if (type === 'mbp-account-ui/address-book/get/FAILURE' && error.response && error.response.status === 404) {
            data.isReadyState = true;
            data.isFetchingModal = false;
        } else if (type === 'mbp-account-ui/recipient/save/FAILURE' && error.response && error.response.status === 400) {
            data.isReadyState = true;
            data.isFetchingModal = false;
            const newAction = { ...action };
            newAction.error.message = ['<p class="bold large">Oops!</p><p>', 'It appears that you already have this recipient in the list. Please add a different recipient.', '</p>'].join('');
            const modalState = showErrorDialog(state, newAction);
            data.modal = modalState.modal;
            data.modalHistory = modalState.modalHistory;
        } else {
            const modalState = showErrorDialog(state, action);
            data.isReadyState = true;
            data.modal = modalState.modal;
            data.modalHistory = modalState.modalHistory;
        }
        return Helper.setState(state, data);
    }
    return Helper.setState(state, { isReadyState: true, toggleState: !toggleState });
};

const SetReadyStateSuccess = (state, action) => setReadyState(state, action);

const HandleRemoveRedirectSuccess = (state) => {
    const data = {
        pageData: {},
    };
    return Helper.setState(state, { ...data });
};

const HandleCloseModalSuccess = (state, action) => {
    const modalState = closeModal(state, action);
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
    };
    return Helper.setState(state, data);
};

const FetchGiftListsSuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { orderId, totalPage, currentPage, occasions, occasionId, productsInCart, noShopTheSiteConfirmation } = action.data;
    // eslint-disable-next-line object-curly-newline
    const { isGiftListUser, pageData, error, origin, isBigGiftlist, isGiftListLoaded } = action.data;
    let { recipients } = action.data;
    const { giftList: { pagination }, toggleState } = state;

    // Update product add to cart from productInCart list
    recipients = Helper.updateRecipientsFromCartItems(recipients, occasionId, productsInCart);
    const remainingRecipient = Helper.checkRemainingRecipient(recipients, occasionId);

    const recipientsByPage = !isEmpty(recipients[occasionId]) ? recipients[occasionId] : [];
    // pagination.count = recipientsByPage.length;
    /* istanbul ignore next */
    const recipient = recipientsByPage.length === 1 ? recipientsByPage[0] : {};
    const data = {
        giftList: {
            orderId,
            error,
            occasionId,
            occasions,
            recipient,
            recipients,
            pagination,
            recipientsByPage,
            isGiftListUser,
            isBigGiftlist,
            isGiftListLoaded,
            totalPage,
            currentPage,
            productsInCart,
            noShopTheSiteConfirmation,
            remainingRecipient,
        },
        origin,
        pageData,
        toggleState: !toggleState,
    };

    if (error && error.errorMessage && error.type === 'modal') {
        const errorState = showErrorDialog(state, { error });
        data.isReadyState = true;
        data.modal = errorState.modal;
        data.modalHistory = errorState.modalHistory;
    }

    return Helper.setState(state, data);
};

const FetchGiftlistOccasions = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { giftList: { occasions: data?.occasions } });
};

const HandleNavigateToPageSuccess = (state, action) => {
    const actionObj = { ...action };
    const { url } = actionObj.data;
    actionObj.data.pageData = { url };
    return FetchGiftListsSuccess(state, actionObj);
};

const HandleAuth0LoginClickedSuccess = (state, action) => {
    const { toggleState } = state;
    const { data } = action;
    data.toggleState = !toggleState;
    return Helper.setState(state, data);
};

const HandleGiftListSubmitLinkUserSuccess = (state, action) => FetchGiftListsSuccess(state, action);

const HandleGiftListPaginationChangedSuccess = (state, action) => {
    const { data: actionData } = action;
    const { toggleState } = state;
    // Update product add to cart from productInCart list
    actionData.recipients = Helper.updateRecipientsFromCartItems(actionData.recipients, actionData.occasionId, actionData.productsInCart);

    actionData.remainingRecipient = Helper.checkRemainingRecipient(actionData.recipients, actionData.occasionId);
    actionData.recipientsByPage = actionData.recipients[actionData.occasionId];
    delete actionData.target;
    const recipient = actionData.recipientsByPage < 2 ? actionData.recipientsByPage[0] : {};
    const { noShopTheSiteConfirmation } = actionData;
    const data = {
        giftList: { ...actionData, recipient, noShopTheSiteConfirmation },
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
    // return state;
};

const HandleGiftListRecipientClickedSuccess = (state, action) => {
    const { recipient, noShopTheSiteConfirmation } = action.data;
    const { giftList } = state;
    const title = `${recipient.firstname} ${recipient.lastname}`;
    const modalState = openModal(
        state,
        title,
        recipient,
        'has-footer-content-inside',
        'RecipientDetails',
    );
    giftList.noShopTheSiteConfirmation = noShopTheSiteConfirmation;
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        giftList,
    };
    return Helper.setState(state, data);
};

const HandleGiftListAddNewRecipientClickedSuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { locations, states, countries, APOFPO, relationships, addressBooks, template } = action.data;
    const layout = template === 'desktop' ? 'DesktopAddressForm' : 'AddressForm';
    const customClass = template === 'desktop' ? 'dialog desktop smallWidth' : 'dialog';
    const modalState = openModal(
        state,
        'Add New Recipient',
        {},
        customClass,
        layout,
        'GiftListRecipientForm',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient: {},
        locations,
        states,
        countries,
        APOFPO,
        relationships,
        addressBooks,
    };
    return Helper.setState(state, data);
};

const HandleAccountSignInSuccess = (state, action) => {
    const { pageData } = action.data;
    return Helper.setState(state, { pageData, isAuthenticated: true });
};

const HandleSubmitCreateAccountSuccess = (state, action) => {
    const { pageData } = action.data;
    return Helper.setState(state, { pageData, isAuthenticated: true });
};

const FetchUserProfileSuccess = (state, action) => {
    const { toggleState } = state;
    const { data } = action;
    data.toggleState = !toggleState;
    // initialize redirect URL for Gift List
    data.pageData = {
        url: '',
    };
    return Helper.setState(state, data);
};

const UpdateUserProfileSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const FetchAddressBookSuccess = (state, action) => {
    const { recipients } = action.data;
    recipients.forEach((recipient, index) => {
        const { Addresses } = recipient;
        const billingIndex = Addresses.findIndex((a) => a.IsBillingAddress === true);
        if (billingIndex > -1) {
            delete Addresses[billingIndex];
            if (isEmpty(recipients[index].Addresses)) delete recipients[index];
        }
    });
    const data = { ...action.data };
    data.recipients = recipients.filter((r) => r !== null);
    return Helper.setState(state, data);
};

const FetchBillingAddressSuccess = (state, action) => {
    const { data } = action;
    const { wallet } = data;
    const sortedWallet = orderBy((wallet || []), 'subsIndicator', 'desc');
    data.wallet = sortedWallet;
    return Helper.setState(state, data);
};

const FetchOrderByEmailSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const FetchOrderDetailByOrderNumberSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const fetchBuyAgainDetailsSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const FetchEmailPreferenceSuccess = (state, action) => {
    const { toggleState } = state;
    const { data: responseData, errorModal } = action;
    const {
        response: { status }, timestamp, error, isSubscribed, retrieveEmailMessage,
    } = responseData;
    const data = {
        email: responseData.email,
        error,
        toggleState: !toggleState,
        isSubscribed,
        timestamp,
        retrieveEmailMessage,
    };
    if (((error && error.message) || status !== 200) && errorModal) {
        const errorState = showErrorDialog(state, data);
        errorState.isReadyState = true;
        return errorState;
    }
    return Helper.setState(state, data);
};

const FetchEmailAddressSuccess = (state, action) => {
    const { toggleState } = state;
    const { data: responseData } = action;
    const data = {
        email: responseData.email,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const FetchGiftListEmailAddressFromIdSuccess = (state, action) => {
    const { toggleState, giftList } = state;
    const { indivId, isCodeSentToEmail } = action.data;
    const { data: responseData } = action;
    const {
        response: { status, data: { email } }, error,
    } = responseData;
    giftList.email = email;
    giftList.indivId = indivId;
    giftList.isCodeSentToEmail = isCodeSentToEmail;
    const data = {
        giftList,
        error,
        toggleState: !toggleState,
    };
    if ((error && error.message) || status !== 200) {
        const errorState = showErrorDialog(state, data);
        return errorState;
    }
    return Helper.setState(state, data);
};

const FetchSubscriptionsSuccess = (state, action) => (
    Helper.setState(state, action.data)
);

const FetchSubscriptionsByStatusSuccess = (state, action) => (
    Helper.setState(state, action.data)
);

const HandleCancelSubscriptionSuccess = (state, action) => {
    const { subscriptions, toggleState } = state;
    subscriptions.filter((subscription) => subscription.ruleId === action.data.ruleId).forEach((subscription) => {
        subscription.subscriptionStatus = 'CANCELLED';  // eslint-disable-line no-param-reassign
    });
    return Helper.setState(state, { subscriptions, toggleState: !toggleState /* force refresh */ });
};

const HandleMassCancelSubscriptionSuccess = (state, action) => {
    const { subscriptionsCancelledStatus, cancellationError } = action.data;
    const { subscriptions, toggleState } = state;
    subscriptions.forEach((subscription) => {
        subscription.subscriptionStatus = 'CANCELLED';  // eslint-disable-line no-param-reassign
    });
    return Helper.setState(state, {
        subscriptions, subscriptionsCancelledStatus, cancellationError, toggleState: !toggleState,
    });
};

const HandleUpdateSubscriptionSuccess = (state, action) => {
    const { subscriptions, toggleState } = state;
    const updateTypeToStatusMap = {
        skip: 'SKIPPED',
        pause: 'PAUSED',
        resume: 'RESUMED',
    };
    const updatedSubscriptions = subscriptions?.map((subscription) => {
        if (subscription.ruleId === action.data.ruleId) {
            const updatedSubscription = { ...subscription };
            if (action?.data?.nextShippingDate) {
                updatedSubscription.nextOrderDate = format(new Date(action?.data?.nextShippingDate), 'MMM dd, yyyy');
            }
            updatedSubscription.subscriptionStatus = updateTypeToStatusMap[action.data.updateType] || 'UPDATED';
            return updatedSubscription;
        }
        return subscription;
    }) || [];

    return Helper.setState(state, {
        subscriptions: updatedSubscriptions, nextShippingDate: action?.data?.nextShippingDate, toggleState: !toggleState, /* force refresh */
    });
};

const HandleSubscriptionSwapSuccess = (state, action) => {
    const { subscriptions } = state;
    const {
        data: {
            data: {
                data: {
                    productId, productName, productPrice, productImageCategory, productImageThumbnail,
                }, ruleId,
            }, isError, isSuccess, message,
        },
    } = action;
    const updatedSubscriptions = subscriptions?.map((subscription) => {
        if (isSuccess && subscription.ruleId === ruleId) {
            const updatedSubscription = { ...subscription };
            updatedSubscription.productId = productId;
            updatedSubscription.productName = productName;
            updatedSubscription.price = productPrice;
            updatedSubscription.subscriptionStatus = 'UPDATED';
            updatedSubscription.productImageCategory = productImageCategory;
            updatedSubscription.productImageThumbnail = productImageThumbnail;
            return updatedSubscription;
        }
        return subscription;
    }) || [];

    return Helper.setState(state, {
        subscriptions: updatedSubscriptions,
        swapSubscriptionResponse: { isError, isSuccess, message },
    });
};

const HandleClearSwapSubscriptionSuccess = (state) => Helper.setState(state, { swapSubscriptionResponse: { isError: false, isSuccess: false, message: '' } });

const HandleFetchSubscriptionsSuccess = (state) => Helper.setState(state, { fetchingSubscriptions: true });

const HandleGiftListSendCodeToEmailSuccess = (state, action) => {
    const { toggleState, giftList } = state;
    const { email, error } = action.data;
    giftList.email = email;
    giftList.error = error;
    // eslint-disable-next-line no-unneeded-ternary
    giftList.isCodeSentToEmail = (error && error.errorMessage) ? false : true;
    const data = {
        giftList,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleGiftListSubmitVerificationCodeSuccess = (state, action) => {
    const { toggleState, giftList } = state;
    const { email, error } = action.data;
    giftList.email = email;
    giftList.error = error;
    giftList.isCodeVerified = !(error.errorMessage);
    const data = {
        giftList,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleGiftListLoginWithCustNbrSuccess = (state) => {
    const { toggleState } = state;
    const data = {
        pageData: { url: '/auth/login' },
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleUpdateEmailPreferenceSuccess = (state, action) => {
    const { toggleState } = state;
    const { data: responseData } = action;
    // eslint-disable-next-line object-curly-newline
    const { response: { status }, timestamp, error, isSubscribed } = responseData;
    const data = {
        email: responseData.email,
        error,
        toggleState: !toggleState,
        isSubscribed,
        timestamp,
    };
    if ((error && error.message) || status !== 200) {
        const errorState = showErrorDialog(state, data);
        return errorState;
    }
    return Helper.setState(state, data);
};

const HandleSubmitProfileAndPasswordSuccess = (state) => {
    const { toggleState } = state;
    const data = {
        buttonType: 'SubmitProfile',
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleUpdateFirstnameLastnameSuccess = (state, action) => {
    const { toggleState } = state;
    const { data: responseData } = action;
    // eslint-disable-next-line object-curly-newline
    const { firstName, lastName, response: { status: requestStatus }, error } = responseData;
    let status = '';
    try {
        ({ response: { data: { status } } } = responseData);
        // eslint-disable-next-line no-empty
    } catch (ex) { }
    status = status || 'failed';
    const successMessage = requestStatus === 200 ? 'You have successfully changed your first and last name. Please re-login to see the update.' : '';
    error.name = 'updateFirstNameAndLastName';
    const data = {
        status,
        firstName,
        lastName,
        buttonType: '', // reset submit button
        successMessage,
        template: 'updateFirstNameAndLastName',
        error,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleUpdatePasswordSuccess = (state, action) => {
    const { toggleState } = state;
    const { data: responseData } = action;
    const { response: { status: requestStatus }, error } = responseData;
    let status = '';
    try {
        ({ response: { data: { status } } } = responseData);
        // eslint-disable-next-line no-empty
    } catch (ex) { }
    status = status || 'failed';
    const successMessage = requestStatus === 200 ? 'You have successfully changed your password.' : '';
    error.name = 'updatePassword';
    const data = {
        status,
        buttonType: '', // reset submit button
        isPasswordTemplate: true,
        passwordSuccessMessage: successMessage,
        error,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleUpdateNewEmailAddressSuccess = (state, action) => {
    const { toggleState } = state;
    const { data: responseData } = action;
    // eslint-disable-next-line object-curly-newline
    const { template, response: { status }, newEmail, error } = responseData;
    error.name = 'updateNewEmailAddress';
    const data = {
        email: responseData.email,
        buttonType: '',
        error,
        toggleState: !toggleState,
        newEmail,
    };
    if (template !== 'desktop') {
        if ((error && error.message) || status !== 200) {
            const errorState = showErrorDialog(state, data);
            return errorState;
        }
    }
    return Helper.setState(state, data);
};

const HandleClearEmailAddressSuccess = (state) => Helper.setState(state, { newEmail: '' });

const HandleClickResetPasswordSuccess = (state, action) => {
    const { pageData } = action.data;
    return Helper.setState(state, { pageData });
};

const HandleSubmitResetPasswordSuccess = (state, action) => {
    const { pageData } = action.data;
    return Helper.setState(state, { pageData });
};

const HandleClickContinueShoppingSuccess = (state, action) => {
    const { pageData } = action.data;
    return Helper.setState(state, { pageData });
};

const HandleClearPageDataSuccess = (state) => Helper.setState(state, { pageData: {} });

const HandleOpenModalSuccess = (state, action) => {
    const { modal } = action.data;
    const modalState = openModal(
        state,
        modal.title,
        modal.content,
        modal.customClass,
        modal.layout,
        modal.config,
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
    };
    return Helper.setState(state, { data });
};

const HandleUpdateModalSuccess = (state, action) => {
    const { modal } = action.data;
    const data = {
        modal,
    };
    return Helper.setState(state, { data });
};

const HandleEditRecipientClickedSuccess = (state, action) => {
    const {
        recipient, locations, states, countries, APOFPO, relationships, AddressId, AddressBookEntryId,
    } = action.data;
    // Should validate recipient.id?
    const modalState = openModal(
        state,
        'Address',
        {},
        'dialog',
        'AddressForm',
        'RecipientForm',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient,
        locations,
        states,
        countries,
        AddressId,
        AddressBookEntryId,
        APOFPO,
        relationships,
    };
    return Helper.setState(state, data);
};

const HandleGiftListEditRecipientClickedSuccess = (state, action) => {
    const {
        recipient, locations, states, countries, APOFPO,
        relationships, AddressId, AddressBookEntryId, template, isGiftlistV2,
    } = action.data;
    const layout = template === 'desktop' ? 'dialog desktop smallWidth' : 'dialog';
    const titleModal = isGiftlistV2 ? 'EDIT RECIPIENT' : 'Edit Address';
    const customClass = template === 'desktop' ? 'DesktopAddressForm' : 'AddressForm';
    // Should validate recipient.id?
    const modalState = openModal(
        state,
        titleModal,
        {},
        layout,
        customClass,
        'GiftListRecipientForm',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient,
        locations,
        states,
        countries,
        AddressId,
        AddressBookEntryId,
        APOFPO,
        relationships,
    };
    return Helper.setState(state, data);
};

const ThreadSetAddressVerificationStateSuccess = (state, action) => {
    const { toggleState, common } = state;
    const { isVerified } = action.data;
    common.addressVerification.isAddressVerification = isVerified;
    const data = {
        common,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

// The found results are stored in a a new object and the original search query string is passed as well for comparison purposes.
const ThreadSearchAddressSuccess = (state, action) => {
    const { toggleState } = state;
    const { searchResults, searchError } = action.data;
    let { common } = state;
    if (!common) {
        common = { addressVerification: {} };
    }
    common.addressVerification.searchResults = searchResults;
    common.addressVerification.searchError = searchError;
    common.addressVerification.isAddressVerification = false;
    const data = {
        common,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

// The found results are stored in a a new object and the original search query string is passed as well for comparison purposes.
const HandleVerifyAddressSuccess = (state, action) => {
    const { toggleState } = state;
    const { searchResults, searchError } = action.data;
    let { common } = state;
    if (!common) {
        common = { addressVerification: {} };
    }
    common.addressVerification.searchResults = searchResults;
    common.addressVerification.searchError = searchError;
    common.addressVerification.isAddressVerification = true;
    const data = {
        common,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

// // If the user clicked "No matches found" or changes focus from the dropdown, this action is called to clear the results.
// const HandleClearAutoSearchSuccess = state =>
//     Helper.setState(state, { searchResults: {} });

// The selected address is made up of an array of values. Each value is converted to a key into an object and used to construct the 'selectedAddress' object
// that will be returned.
const ThreadSelectAnAddressSuccess = (state, action) => {
    const { toggleState } = state;
    const { response, isAccountUsingZipCodeSpecificDeliveryRoutes } = action.data;
    const { components, address } = response.data;
    let { common } = state;
    if (!common) {
        common = { addressVerification: {} };
    }
    const nComponents = normalizeArrayToObject(components);
    const nAddress = normalizeArrayToObject(address);
    let apt = '';
    if (nAddress.addressLine2 && nAddress.addressLine3) {
        apt = `${nAddress.addressLine2} ${nAddress.addressLine3}`;
    } else if (nAddress.addressLine2) {
        apt = nAddress.addressLine2;
    }
    const selectedAddress = {
        street: nAddress.addressLine1,
        apt,
        city: nAddress.locality,
        state: nAddress.province,
        country: countryISOAlfa2Code[nComponents.countryISO1],
        zipcode: isAccountUsingZipCodeSpecificDeliveryRoutes ? nAddress.postalCode : nAddress.postalCode.split('-')[0],
    };
    common.addressVerification.searchResults = [];
    common.addressVerification.selectedAddress = selectedAddress;
    common.addressVerification.isAddressVerification = false;
    // Search results are passed as an empty object to clear them from props.
    const data = {
        toggleState: !toggleState,
        common,
    };
    return Helper.setState(state, data);
};

const HandleClearAddressVerificationSuccess = (state) => {
    const { common } = state;
    common.addressVerification.searchResults = [];
    common.addressVerification.searchError = {};
    common.addressVerification.isAddressVerification = false;
    const data = { common };
    return Helper.setState(state, { ...data });
};

const HandleSubmitAddNewRecipientSuccess = (state, action) => {
    const {
        occasionId,
        templateName,
        AddressBookEntryId,
        // eslint-disable-next-line object-curly-newline
        response: { addressId, address, addressEntry, recipients: giftListRecipients, currentPage, totalPage },
    } = action.data;
    const { recipients, giftList, toggleState } = state;
    if (!address) return state;

    const { IsBillingAddress } = address;
    const modalState = closeModal(state);
    const { modal } = modalState;
    // const states = Helper.refactorStateData(States);
    // const countries = Helper.refactorObjectData(Countries);

    let data;

    if (!IsBillingAddress) {
        // Get recipient index
        let recipient;
        if (AddressBookEntryId) {
            const index = recipients.findIndex((item) => item.AddressBookEntryId === AddressBookEntryId);
            recipient = recipients[index];
            const { Addresses } = recipient;
            Addresses[0] = { ...address };
            recipient.EmployerName = addressEntry.EmployerName;
            recipient.FirstName = addressEntry.FirstName;
            recipient.LastName = addressEntry.LastName;
            recipient.NickName = addressEntry.FirstName;
            recipient.PhoneNumber = addressEntry.PhoneNumber;
            recipient.RelationShip = addressEntry.RelationShip;
        } else {
            // eslint-disable-next-line no-lonely-if
            if (templateName !== 'GiftListRecipientForm') {
                recipient = { ...addressEntry };
                recipient.NumberOfAddresses += 1;
                recipient.Addresses = [];
                recipient.Addresses.push({ ...address });
                recipients.push(recipient);
            } else {
                //
                // TODO: need to update addToCart from the cartItems object
                //
                // Update recipients in giftList
                giftList.recipients[occasionId] = giftListRecipients[occasionId];
                giftList.recipientsByPage = giftListRecipients[occasionId];
                giftList.currentPage = currentPage;
                giftList.totalPage = totalPage;
                // Update modal content
                const updatedRecipient = giftList.recipients[occasionId].filter((item) => item.addressId === addressId);
                if (!isEmpty(updatedRecipient)) {
                    [modal.content] = updatedRecipient;
                    modal.title = `${updatedRecipient[0].firstname} ${updatedRecipient[0].lastname}`;
                }
            }
        }

        data = {
            modal,
            modalHistory: modalState.modalHistory,
            recipient,
            recipients,
            giftList,
            AddressId: null,
            AddressBookEntryId: null,
            toggleState: !toggleState,
            addNewConfig: '',
            singleAddressGet: {},
            addFormLable: 'Address Book',
            addForm: '',
        };
    } else {
        let { billingAddress } = state;
        if (!billingAddress) return state;
        let Addresses = [{}];
        try {
            // eslint-disable-next-line prefer-destructuring
            ({ Addresses } = billingAddress[0]);
        } catch (ex) {
            billingAddress = [{ Addresses: [{}] }];
            // eslint-disable-next-line prefer-destructuring
            ({ Addresses } = billingAddress[0]);
        }

        billingAddress[0].EmployerName = addressEntry.EmployerName;
        billingAddress[0].FirstName = addressEntry.FirstName;
        billingAddress[0].LastName = addressEntry.LastName;
        billingAddress[0].PhoneNumber = addressEntry.PhoneNumber;
        billingAddress[0].RelationShip = addressEntry.RelationShip;
        billingAddress[0].AddressBookEntryId = addressEntry.AddressBookEntryId;

        Addresses[0].AddressLineOne = address.AddressLineOne;
        Addresses[0].AddressLineTwo = address.AddressLineTwo;
        Addresses[0].AddressType = address.AddressType;
        Addresses[0].City = address.City;
        Addresses[0].Country = address.Country;
        Addresses[0].PostalCode = address.PostalCode;
        Addresses[0].StateProvince = address.StateProvince;

        data = {
            modal: modalState.modal,
            modalHistory: modalState.modalHistory,
            billingAddress,
            AddressId: null,
            AddressBookEntryId: null,
            addForm: 'CardInfo',
            openDialog: true,
            addNewConfig: '',
            addFormLable: 'Payment & Billing',
            toggleState: !toggleState,
        };
    }
    return Helper.setState(state, data);
};

const HandleRemoveRecipientClickedSuccess = (state, action) => {
    const { recipient, AddressId, addressType } = action.data;
    // Should validate recipient.id?
    const modalState = openModal(
        state,
        'Delete Recipient',
        {},
        'dialog no-x-button',
        'RemoveRecipient',
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient,
        AddressId,
        addressType,
    };
    return Helper.setState(state, data);
};

const HandleSubmitRemoveRecipientSuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { AddressBookEntryId, response, addressType } = action.data;
    const { recipients, toggleState, giftList } = state;
    // Delete error
    // TODO: need to display error message
    if (response.status !== 200) return state;

    // Should validate recipient.id?
    const newState = { ...state };
    delete newState.AddressId;
    newState.recipient = {};

    if (AddressBookEntryId && addressType === 'ADDRESS_BOOK') {
        const recipientIndex = recipients.findIndex((item) => item.AddressBookEntryId === AddressBookEntryId);
        const recipient = recipients[recipientIndex];
        delete recipient.Addresses[0];
        const recipientAddress = recipient.Addresses.filter((a) => a.AddressType !== 'Billing');
        if (recipientAddress.length === 0) {
            // delete recipients[recipientIndex] if there is no more recipient address
            newState.recipients = recipients.filter((item) => item.AddressBookEntryId !== AddressBookEntryId);
        }
    } else if (response.giftList.occasionId && !isEmpty(response.giftList) && !isEmpty(response.giftList.recipients)) {
        giftList.recipients[response.giftList.occasionId] = response.giftList.recipients[response.giftList.occasionId];
        giftList.recipientsByPage = response.giftList.recipients[response.giftList.occasionId];
        giftList.currentPage = response.giftList.currentPage;
        giftList.totalPage = response.giftList.totalPage;
    }

    const modalState = closeAllModals(state);

    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient: {},
        recipients: newState.recipients,
        giftList,
        AddressId: null,
        toggleState: !toggleState,
        addNewConfig: '',
        singleAddressGet: {},
        addFormLable: 'Address Book',
        addForm: '',
    };
    return Helper.setState(state, data);
};

const HandleAddNewRecipientSuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { locations, states, countries, APOFPO, relationships, addressBooks } = action.data;
    const modalState = openModal(
        state,
        'New Recipient',
        {},
        'dialog',
        'AddressForm',
        'RecipientForm',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient: {},
        locations,
        states,
        countries,
        APOFPO,
        relationships,
        addressBooks,
    };
    return Helper.setState(state, data);
};

const HandleGiftListRecommendationClickedSuccess = (state, action) => {
    const {
        product, recipient, comparedProducts = [], template = '',
    } = action.data;
    const layout = template === 'desktop' ? 'DesktopGiftListProductDetails' : 'GiftListProductDetails';
    const comparedClass = comparedProducts.length > 1 ? `compare-${comparedProducts.length}-products` : 'smallWidth';
    const customClass = template === 'desktop' ? `dialog desktop ${comparedClass}` : 'dialog';
    const modalState = openModal(
        state,
        'Product Details',
        { product, recipient, comparedProducts },
        customClass,
        layout,
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
    };
    return Helper.setState(state, data);
};

const HandleGiftListAddToCartSuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { recipient, recipient: { id: recipientId, occasionId } } = action.data;
    const {
        closeModal: forceCloseModal, response, error, productAddedToCart,
    } = action.data;
    // eslint-disable-next-line object-curly-newline
    const { modal, modalHistory, giftList, toggleState } = state;
    const recipients = [...giftList.recipients[occasionId]];
    const newRecipient = { ...recipient };
    const pageData = {};
    const index = recipients.findIndex((item) => item.id === recipientId);
    if (index > -1 && !isEmpty(response)) {
        // Update product add to cart from productInCart list
        newRecipient.error = {};
        giftList.productAddedToCart = productAddedToCart;
        giftList.recipient = newRecipient;
        giftList.productsInCart = response.productsInCart;
        giftList.recipients = Helper.updateRecipientsFromCartItems(giftList.recipients, occasionId, response.productsInCart);
        giftList.remainingRecipient = Helper.checkRemainingRecipient(giftList.recipients, occasionId);
        // giftList.recipients[occasionId] = recipients;
        modal.content = recipients[index];
        let modalState = { modal, modalHistory };
        if (forceCloseModal) modalState = closeModal(state);
        if (response.seoproductDisplayURL) {
            const productURL = response.seoproductDisplayURL.replace(/^(http[s]?:)?\/\/[^/]+/, '');
            pageData.url = productURL;
        }
        const data = {
            modal: modalState.modal,
            modalHistory: modalState.modalHistory,
            giftList,
            pageData,
            toggleState: !toggleState,
        };
        if (error && error.errorMessage && !error.type) {
            // Show error modal
            const errorState = showErrorDialog(state, { error });
            data.modal = errorState.modal;
            data.modalHistory = errorState.modalHistory;
        } else if (error && error.errorMessage && error.type) {
            // Set error as input field / inline error message
            data.giftList.error = error;
        }
        return Helper.setState(state, data);
    } if (response.seoproductDisplayURL) {
        // Redirect to seoproductDisplayURL
        const productURL = response.seoproductDisplayURL.replace(/^(http[s]?:)?\/\/[^/]+/, '');
        pageData.url = productURL;
        const data = {
            pageData,
            giftList,
            toggleState: !toggleState,
        };
        return Helper.setState(state, data);
    } if (error && error.errorMessage && !error.type) {
        // Show error modal
        const errorState = showErrorDialog(state, { error });
        const data = {
            modal: errorState.modal,
            modalHistory: errorState.modalHistory,
            giftList,
            pageData,
            toggleState: !toggleState,
        };
        return Helper.setState(state, data);
    } if (error && error.errorMessage && error.type) {
        // Send error to input field / inline error message
        giftList.error = error;
        const data = {
            giftList,
            pageData,
            toggleState: !toggleState,
        };
        return Helper.setState(state, data);
    }
    return state;
};

const HandleGiftListChooseSomethingDifferentSuccess = (state, action) => HandleGiftListAddToCartSuccess(state, action);

const HandleGiftListShopTheSiteClickedSuccess = (state, action) => {
    const { toggleState } = state;
    const { recipient, template } = action.data;
    const layout = template === 'desktop' ? 'DesktopConfirmShopTheSite' : 'ConfirmShopTheSite';
    const customClass = template === 'desktop' ? 'dialog no-x-button small-dailog' : 'dialog no-x-button';
    const modalState = openModal(
        state,
        'Shop the Site for Gift List Recipients',
        {},
        customClass,
        layout,
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        pageData: { recipient },
        toggleState: !toggleState,
    };
    return Helper.setState(state, { ...data });
};

const HandleGiftListConfirmShopTheSiteClickedSuccess = (state, action) => {
    const { toggleState, giftList } = state;
    const { noShopTheSiteConfirmation } = action.data;
    giftList[SHOP_THE_SITE_KEY] = action.data[SHOP_THE_SITE_KEY];
    giftList.noShopTheSiteConfirmation = noShopTheSiteConfirmation;
    const modalState = closeAllModals(state);
    const pageData = {
        url: '/',
    };
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        pageData,
        giftList,
        toggleState: !toggleState,
    };
    return Helper.setState(state, { ...data });
};

const HandleGiftListEnterItemNumberClickedSuccess = (state, action) => {
    const { recipient, template } = action.data;
    const { giftList } = state;
    const layout = template === 'desktop' ? 'DesktopEnterItemNumber' : 'EnterItemNumber';
    const customClass = template === 'desktop' ? 'dialog desktop smallWidth' : 'dialog';
    const modalState = openModal(
        state,
        'Item Number',
        {},
        customClass,
        layout,
    );
    giftList.recipient = recipient;
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        giftList,
    };
    return Helper.setState(state, data);
};

const HandleGiftListCheckoutClickedSuccess = (state, action) => {
    const { occasionId, recipients, target } = action.data;
    const { toggleState, giftList } = state;
    let orderId = '';
    recipients[occasionId].forEach((item) => {
        if (item.addToCart && !isEmpty(item.cartDetail) && item.cartDetail.orderId) {
            ({ orderId } = item.cartDetail);
        }
    });

    if (orderId) {
        const url = `/checkout/cart/${orderId}`;
        const data = {
            pageData: { url, target },
            toggleState: !toggleState,
        };
        return Helper.setState(state, { ...data });
    }
    const errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', 'Something went wrong on the page!', '</p>'].join('');
    const error = {
        errorMessage,
    };
    const errorState = showErrorDialog(state, { giftList, error });
    errorState.isReadyState = true;
    return errorState;
};

const HandleChangeSortBySuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { sortBy } = action.data;
    let { recipients } = state;
    const { toggleState } = state;

    if (sortBy === 'Name') {
        recipients = orderBy(recipients, 'FirstName', 'asc');
    } else if (sortBy === 'Relationship') {
        recipients = orderBy(recipients, 'RelationShip', 'asc');
    }
    const data = {
        recipients,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleClickEditBillingAddressSuccess = (state, action) => {
    // eslint-disable-next-line object-curly-newline
    const { billingAddress, wallet, template } = action.data;
    // const { locations, states, countries, APOFPO, relationships } = action.data;
    const { countries } = action.data;
    const { toggleState } = state;
    let editBillingAddress = {};
    const { AddressBookEntryId } = editBillingAddress;
    let AddressId = null;
    try {
        editBillingAddress = { ...billingAddress[0] };
        // eslint-disable-next-line prefer-destructuring
        ({ AddressId } = editBillingAddress.Addresses[0]);
    } catch (ex) {
        AddressId = null;
        editBillingAddress = {};
    }
    const layout = (template === 'desktop') ? 'DesktopCreditCardAndBillingForm' : 'AddressForm';
    let modalState = {};
    const title = (AddressId === null) ? 'Billing Address' : 'Billing Address';
    const customClass = (template === 'desktop') ? 'dialog desktop wide' : 'dialog';
    let config = (AddressId === null) ? 'AddBillingAddress' : 'EditBillingAddress';
    if (template !== 'desktop') {
        config = 'BillingForm';
    }
    modalState = openModal(
        state,
        title,
        { ...wallet, billingAddress, countries },
        customClass,
        layout,
        config,
        true,
    );
    const data = {
        modal: modalState.modal,
        modalHistory: wallet ? [] : modalState.modalHistory,
        AddressBookEntryId,
        AddressId,
        recipient: editBillingAddress,
        toggleState: !toggleState,
        addNewConfig: wallet ? 'BillingForm' : '',
        template,
    };

    return Helper.setState(state, data);
};

// eslint-disable-next-line arrow-body-style
const HandleEditPasswordSuccess = (state) => {
    return state;
};

const HandleClickAddNewCreditCardSuccess = (state, action) => {
    const { params } = action.data;
    let modalState = {};
    let data = {};
    const layout = (params === 'desktop') ? 'DesktopCreditCardAndBillingForm' : 'CreditCardForm';
    // const customClass = (template === 'desktop') ? 'dialog desktop' : 'dialog';
    const customClass = (params === 'desktop') ? 'dialog desktop wide' : 'dialog';
    if (params === 'desktop') {
        modalState = {
            title: '',
            content: '',
            // customClass: 'cardInfo',
            customClass,
            layout,
            config: '',
        };
        modalState = openModal(
            state,
            'Add Credit Card',
            {},
            customClass,
            layout,
            '',
        );
        data = {
            modal: modalState.modal,
            modalHistory: [],
            addForm: 'EditCardInfo',
            openDialog: true,
            addNewConfig: '',
            addFormLable: 'Add Payment Method',
        };
    } else {
        modalState = openModal(
            state,
            'Add Credit Card',
            {},
            'dialog',
            'CreditCardForm',
            '',
        );
        data = {
            modal: modalState.modal,
            modalHistory: modalState.modalHistory,
        };
    }
    // eslint-disable-next-line object-curly-newline
    return Helper.setState(state, data);
};

const HandleClickEditCreditCardSuccess = (state, action) => {
    const { walletAndBillingInfo, template } = action.data;
    const { toggleState } = state;
    const modalTemplate = (template === 'desktop') ? 'DesktopCreditCardAndBillingForm' : 'CreditCardForm';
    const customClass = (template === 'desktop') ? 'dialog desktop wide' : 'dialog';
    const title = walletAndBillingInfo.walletId ? 'Card' : 'New Card';
    const config = walletAndBillingInfo.walletId ? 'EditCreditCard' : 'AddCreditCard';
    const modalState = openModal(
        state,
        title,
        walletAndBillingInfo,
        customClass,
        modalTemplate,
        config,
        true,
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        toggleState: !toggleState,
        template,
    };
    return Helper.setState(state, data);
};

const HandleDesktopClickEditCreditCardSuccess = (state, action) => {
    const { walletInfo } = action.data;
    const modalState = {
        title: '',
        content: walletInfo,
        customClass: 'cardInfo',
        layout: 'CreditCardForm',
        config: '',
    };
    const data = {
        modal: modalState,
        addForm: 'EditCardInfo',
        openDialog: true,
        addNewConfig: '',
        addFormLable: 'Edit Payment Method',
    };
    return Helper.setState(state, data);
};

const HandleSaveCreditCardSuccess = (state, action) => {
    const { response, wallet: savedCard, error } = action.data;
    const { wallet, toggleState } = state;
    // eslint-disable-next-line object-curly-newline

    // Capture preset-error from API
    if (!isEmpty(error)) {
        if (error.type !== 'field') {
            const errorState = setReadyState(state, action.data);
            return errorState;
        }
        const errorData = {
            toggleState: !toggleState,
            errorField: error,
        };
        return Helper.setState(state, errorData);
    }
    // Capture miscellaneous errors
    const errorObj = { error: { message: 'Oops! Something went wrong.' } };
    if (response.status !== 200) {
        const errorState = setReadyState(state, errorObj);
        return errorState;
    }
    try {
        if (!response.data.result.wallet.walletId) {
            const errorState = setReadyState(state, errorObj);
            return errorState;
        }
    } catch (ex) {
        const errorState = setReadyState(state, errorObj);
        return errorState;
    }

    // Get credit card detail from submitted data;
    // we can get only walletId from the success response
    savedCard.status = 'A';
    delete savedCard.sourceID;
    delete savedCard.tokenFlag;
    delete savedCard.user;

    // Reset default card if user set the new default card
    if (savedCard.subsIndicator === '1') {
        const defaultIndex = wallet.findIndex((w) => w.subsIndicator === '1');
        if (defaultIndex > -1) {
            wallet[defaultIndex].subsIndicator = '0';
        }
    }

    if (savedCard.walletId) {
        const index = wallet.findIndex((w) => w.walletId === savedCard.walletId);
        wallet[index] = { ...savedCard };
    } else {
        savedCard.walletId = response.data.result.wallet.walletId;
        wallet.push(savedCard);
    }
    const sortedWallet = orderBy(wallet, 'subsIndicator', 'desc');
    const modalState = closeModal(state);
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        wallet: sortedWallet,
        addForm: 'CardInfo',
        openDialog: true,
        addNewConfig: '',
        addFormLable: 'Payment & Billing',
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleClickRemoveCreditCardSuccess = (state, action) => {
    const { walletId } = action.data;
    // Should validate recipient.id?
    const modalState = openModal(
        state,
        'Delete Credit Card',
        {},
        'dialog no-x-button',
        'RemoveCreditCard',
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        walletId,
    };
    return Helper.setState(state, data);
};

const HandleRemoveCreditCardSuccess = (state, action) => {
    const { walletId, response } = action.data;
    const { wallet, toggleState } = state;

    // Delete error
    // TODO: need to display error message
    if (response.status !== 200) return state;

    const index = wallet.findIndex((w) => w.walletId === walletId);

    // TODO: need to display error message
    if (index === -1) return state;
    delete wallet[index];
    const newWallet = wallet.filter((w) => w !== null);
    const modalState = closeAllModals(state);
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        wallet: newWallet,
        walletId: null,
        addForm: 'CardInfo',
        openDialog: true,
        addNewConfig: '',
        addFormLable: 'Payment & Billing',
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleSetDefaultCreditCardSuccess = (state, action) => {
    const { walletId, response } = action.data;
    const { wallet, toggleState } = state;

    // Delete error
    // TODO: need to display error message
    if (response.status !== 200) return state;

    const index = wallet.findIndex((w) => w.walletId === walletId);
    const prevIndex = wallet.findIndex((w) => w.subsIndicator === '1');

    // TODO: need to display error message
    if (index === -1 || prevIndex === -1) return state;
    wallet[prevIndex].subsIndicator = '0';
    wallet[index].subsIndicator = '1';

    const sortedWallet = orderBy(wallet, 'subsIndicator', 'desc');

    const data = {
        wallet: sortedWallet,
        walletId: null,
        toggleState: !toggleState,
    };
    return Helper.setState(state, data);
};

const HandleSubmitOrderByZipCodeSuccess = (state, action) => {
    const { toggleState } = state;
    const { data } = action;
    data.toggleState = !toggleState;
    return Helper.setState(state, data);
};
const HandleDesktopAddNewRecipientSuccess = (state, action) => {
    const {
        locations, states, countries, APOFPO, relationships, addressBooks,
    } = action.data;
    const data = {
        recipient: {},
        locations,
        states,
        countries,
        APOFPO,
        relationships,
        addressBooks,
        addFormLable: 'Address Book',
        addForm: '',
        openDialog: true,
    };
    return Helper.setState(state, data);
};
const HandleCloseDialogSuccess = (state) => {
    const data = {
        recipient: {},
        addNewConfig: '',
        singleAddressGet: {},
        addFormLable: '',
        addForm: '',
        openDialog: false,
    };
    return Helper.setState(state, data);
};
const HandleDesktopAddNewFormSuccess = (state, action) => {
    const {
        locations, states, countries, APOFPO, relationships, addressBooks, param,
    } = action.data;
    const data = {
        recipient: {},
        AddressId: '', // new entry
        AddressBookEntryId: '', // new entry
        locations,
        states,
        countries,
        APOFPO,
        relationships,
        addressBooks,
        addNewConfig: param,
        singleAddressGet: {},
        addFormLable: 'Add Address',
        addForm: 'AddForm',
    };
    return Helper.setState(state, data);
};
const HandleDesktopSingleAddressGetSuccess = (state, action) => {
    const data = {
        singleAddressGet: action.data,
        addFormLable: 'Address Book',
        addForm: '',
    };
    return Helper.setState(state, data);
};
const HandleDesktopEditAddressSuccess = (state, action) => {
    const {
        recipient, locations, states, countries, APOFPO, relationships, AddressId, AddressBookEntryId, param,
    } = action.data;
    const data = {
        recipient,
        locations,
        states,
        countries,
        AddressId,
        AddressBookEntryId,
        APOFPO,
        relationships,
        addNewConfig: param,
        singleAddressGet: {},
        addFormLable: 'Edit Address',
        addForm: 'EditForm',
    };
    return Helper.setState(state, data);
};
const HandleDesktopAddressRemoveSuccess = (state, action) => {
    const { recipient, AddressId, addressType } = action.data;
    // Should validate recipient.id?
    const modalState = openModal(
        state,
        '',
        {},
        'dialog small-dailog',
        'DesktopAddressRemove',
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        recipient,
        AddressId,
        addressType,
    };
    return Helper.setState(state, data);
};
const HandleDesktopChangePasswordSuccess = (state) => {
    const modalState = openModal(
        state,
        'Change Password',
        {},
        'dialog',
        'ChangePasswordForm',
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
    };
    return Helper.setState(state, data);
};
const HandleDesktopChangeEmailSuccess = (state) => {
    const modalState = openModal(
        state,
        'Change Email',
        {},
        'dialog',
        'ChangeEmailForm',
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
    };
    return Helper.setState(state, data);
};
const HandleSetDefaultBillingValueSuccess = (state) => {
    const data = {
        addForm: 'CardInfo',
        openDialog: true,
        addNewConfig: '',
        addFormLable: 'Payment & Billing',
    };
    return Helper.setState(state, data);
};
const HandleDesktopDialogConfirmCardSuccess = (state, action) => {
    const { walletId, isDefaultCard, template } = action.data;
    const customClass = template === 'desktop' ? 'dialog small-dailog headless desktop' : 'dialog small-dailog';
    const modalState = openModal(
        state,
        '',
        {},
        customClass,
        'DesktopRemoveCreditCard',
        '',
    );
    const data = {
        modal: modalState.modal,
        modalHistory: modalState.modalHistory,
        walletId,
        isDefaultCard,
    };
    return Helper.setState(state, data);
};
const HandleDesktopClickCancelButtonSuccess = (state) => {
    const { wallet } = state;
    const sortedWallet = orderBy(wallet, 'subsIndicator', 'desc');
    const data = {
        wallet: sortedWallet,
        addForm: 'CardInfo',
        openDialog: true,
        addNewConfig: '',
        addFormLable: 'Payment & Billing',
    };
    return Helper.setState(state, data);
};

const HandleProcessReplaceOrderSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleProcessPartialRefundSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleClearIsAvailableSuccess = (state) => Helper.setState(state, { isAvailable: null });

const HandleClearPartialRefundErrorSuccess = (state) => Helper.setState(state, { partialRefundResponseError: {} });

const HandleClearReplaceResponseSuccess = (state) => Helper.setState(state, { replaceResponse: {} });

const HandleProcessCancelOrderSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

// const HandleCsiGuardrailCheckSuccess = (state, action) => {
//     const { data } = action;
//     return Helper.setState(state, data);
// };

const HandleSubscriptionRecipientFormSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleProcessCheckAvailabilitySuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleMmoGetDeliveryDatesSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleMmoSubmitModifiedOrderSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleFetchProductAvailabilitySuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleFetchHdEditableCheckSuccess = (state, action) => {
    const { data, data: { isHDOrderModifiable } } = action;
    let { isHDOrderModifiableResult } = state;
    if (Array.isArray(isHDOrderModifiableResult)) {
        isHDOrderModifiableResult.push(isHDOrderModifiable);
    } else {
        isHDOrderModifiableResult = [isHDOrderModifiable];
    }
    const sortedArray = orderBy(isHDOrderModifiableResult, 'recipientSeqNumber');
    data.isHDOrderModifiableResult = sortedArray;
    return Helper.setState(state, data);
};

const ClearProductAvilabilitySuccess = (state) => {
    const data = {
        productAvailable: '',
        productAvilableStatus: '',
    };
    return Helper.setState(state, { ...data });
};

const HandleFetchSubscriptionGiftMessageSuccess = (state, action) => {
    const { data } = action;
    const { clickedId, subscriptionsGiftMessage } = data;
    const { subscriptionMessages = [] } = state;
    const updatedMessageList = [...subscriptionMessages];

    // Check if the new message's ID is already present in the existing array
    const isMessageExist = updatedMessageList.some((message) => message.id === clickedId);

    // If the message doesn't exist, add it to the array; otherwise, update the existing message
    if (!isMessageExist) {
        updatedMessageList.push({ text: subscriptionsGiftMessage, id: clickedId });
    } else {
        // Find the index of the existing message based on its ID
        const index = updatedMessageList.findIndex((message) => message.id === clickedId);

        // Update the message at the found index with the new message
        updatedMessageList[index].text = subscriptionsGiftMessage;
    }

    const updatedData = { ...data, subscriptionMessages: updatedMessageList };
    return Helper.setState(state, updatedData);
};

const HandleUpdateSubscriptionGiftMessageSuccess = (state, action) => {
    const { data } = action;
    const { data: { ruleId, giftMessage } } = data;
    const { subscriptionMessages = [] } = state;
    const updatedMessageList = [...subscriptionMessages];

    // Check if the message's ID is present in the existing array
    const index = updatedMessageList.findIndex((message) => message.id === ruleId);

    // If the message exist, update it to the array
    if (index > -1) {
        updatedMessageList[index].text = giftMessage;
    }

    const updatedData = { ...data, subscriptionMessages: updatedMessageList };
    return Helper.setState(state, updatedData);
};
const ClearSubscriptionGiftMessageSuccess = (state) => {
    const data = {
        subscriptionsGiftMessage: '',
    };
    return Helper.setState(state, { ...data });
};

const ClearOrderHistoryRossDataSuccess = (state) => {
    const data = {
        ROSSOrders: [],
    };
    return Helper.setState(state, { ...data });
};

const ClearRossOrderDataSuccess = (state) => {
    const data = {
        ROSSOrder: {},
    };
    return Helper.setState(state, { ...data });
};

const ClearRossErrorSuccess = (state) => {
    const data = {
        error: { errorMessage: '' },
        isErrorResponse: false,
    };
    return Helper.setState(state, { ...data });
};

const HandleFetchPassportAutoRenewalStatusSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleUpdatePassportAutoRenewalStatusSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleCancelPassportMembershipSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleSetPassportAutoRenewalStatusSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleValidateAgentKeySuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { ...data });
};

const HandleValidateCaptchaSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { ...data });
};

const HandleClearAgentSubMgmtStateSuccess = (state, action) => {
    const { data } = action;

    return Helper.setState(state, {
        ...data, callBack: { url: '' }, customerId: '', customerEmail: '',
    });
};

const HandleClearSubscriptionStateSuccess = (state, action) => (
    Helper.setState(state, action.data)
);

const HandleMarketPlaceFormDataSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleSetAsmCustomerEmailSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleUpdateSubscriptionFrequencySuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const HandleAddAsmAgentNotesSuccess = (state, action) => {
    const { data } = action;
    const { toggleState } = state;
    return Helper.setState(state, { ...data, toggleState: !toggleState });
};

const HandleFetchAsmAgentNotesSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, data);
};

const ClearAsmAgentNotesStateSuccess = (state) => Helper.setState(state, { agentNotes: [] });

const ClearAsmAgentNotesErrTrackingSuccess = (state) => Helper.setState(state, { errorMessage: false, errorFetchingAgentNotes: false });

const SetThumbsUpForDeliveryImageSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { ...data });
};
const ClearCancelPassportMembershipStateSuccess = (state) => Helper.setState(state, { cancelPassportMembershipStatus: '', cancelPassportMembershipStatusError: '' });
const SetRecipientOpenStateSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { recipientOpen: data.payload });
};
const UpdateSubscriptionListSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { subscriptions: data.payload });
};

const SetRecipientVerifyDataSuccess = (state, action) => {
    const { data } = action;
    return Helper.setState(state, { ...data });
};

const ClearRecipientVerifyDataSuccess = (state) => Helper.setState(state, { recipientLandingPage: {} });

const Action = {
    setBusyState,
    setReadyState,
    setBusyFetching,
    setReadyFetching,
    openModal, // For unit test
    closeModal, // For unit test
    openPreviousModal, // For unit test
    SetReadyStateSuccess,
    HandleNavigateToPageSuccess,
    HandleRemoveRedirectSuccess,
    HandleCloseModalSuccess,
    // HandleGiftListOccasionChangeSuccess,
    HandleGiftListPaginationChangedSuccess,
    HandleGiftListRecipientClickedSuccess,
    HandleGiftListAddNewRecipientClickedSuccess,
    HandleGiftListEditRecipientClickedSuccess,
    HandleGiftListRecommendationClickedSuccess,
    HandleGiftListChooseSomethingDifferentSuccess,
    HandleGiftListCheckoutClickedSuccess,
    HandleGiftListShopTheSiteClickedSuccess,
    HandleGiftListConfirmShopTheSiteClickedSuccess,
    HandleGiftListEnterItemNumberClickedSuccess,
    HandleGiftListSubmitLinkUserSuccess,
    HandleAccountSignInSuccess,
    FetchGiftListsSuccess,
    FetchUserProfileSuccess,
    FetchAddressBookSuccess,
    FetchBillingAddressSuccess,
    FetchOrderByEmailSuccess,
    FetchOrderDetailByOrderNumberSuccess,
    fetchBuyAgainDetailsSuccess,
    FetchEmailPreferenceSuccess,
    FetchGiftlistOccasions,
    FetchEmailAddressSuccess,
    FetchGiftListEmailAddressFromIdSuccess,
    FetchSubscriptionsSuccess,
    HandleCancelSubscriptionSuccess,
    HandleMassCancelSubscriptionSuccess,
    HandleUpdateSubscriptionSuccess,
    HandleGiftListSendCodeToEmailSuccess,
    HandleGiftListSubmitVerificationCodeSuccess,
    HandleGiftListLoginWithCustNbrSuccess,
    HandleSubmitCreateAccountSuccess,
    HandleClickResetPasswordSuccess,
    HandleSubmitResetPasswordSuccess,
    HandleClearPageDataSuccess,
    HandleOpenModalSuccess,
    HandleUpdateModalSuccess,
    HandleClickContinueShoppingSuccess,
    HandleEditRecipientClickedSuccess,
    HandleRemoveRecipientClickedSuccess,
    HandleSubmitRemoveRecipientSuccess,
    HandleSubmitAddNewRecipientSuccess,
    HandleVerifyAddressSuccess,
    ThreadSearchAddressSuccess,
    ThreadSelectAnAddressSuccess,
    ThreadSetAddressVerificationStateSuccess,
    // HandleClearAutoSearchSuccess,
    HandleGiftListAddToCartSuccess,
    HandleAddNewRecipientSuccess,
    HandleChangeSortBySuccess,
    HandleClickEditBillingAddressSuccess,
    HandleEditPasswordSuccess,
    HandleClickAddNewCreditCardSuccess,
    HandleSaveCreditCardSuccess,
    HandleClickRemoveCreditCardSuccess,
    HandleRemoveCreditCardSuccess,
    HandleSetDefaultCreditCardSuccess,
    HandleClickEditCreditCardSuccess,
    HandleSubmitOrderByZipCodeSuccess,
    HandleUpdateEmailPreferenceSuccess,
    HandleSubmitProfileAndPasswordSuccess,
    HandleUpdateNewEmailAddressSuccess,
    HandleUpdateFirstnameLastnameSuccess,
    HandleUpdatePasswordSuccess,
    HandleAuth0LoginClickedSuccess,
    HandleDesktopAddNewRecipientSuccess,
    HandleCloseDialogSuccess,
    HandleDesktopAddNewFormSuccess,
    HandleDesktopSingleAddressGetSuccess,
    HandleDesktopEditAddressSuccess,
    HandleDesktopAddressRemoveSuccess,
    HandleDesktopChangePasswordSuccess,
    HandleDesktopChangeEmailSuccess,
    HandleDesktopClickEditCreditCardSuccess,
    HandleSetDefaultBillingValueSuccess,
    HandleDesktopDialogConfirmCardSuccess,
    HandleDesktopClickCancelButtonSuccess,
    HandleProcessCancelOrderSuccess,
    HandleProcessReplaceOrderSuccess,
    HandleProcessPartialRefundSuccess,
    HandleClearIsAvailableSuccess,
    HandleSubscriptionRecipientFormSuccess,
    HandleProcessCheckAvailabilitySuccess,
    HandleMmoGetDeliveryDatesSuccess,
    HandleMmoSubmitModifiedOrderSuccess,
    HandleFetchProductAvailabilitySuccess,
    HandleFetchSubscriptionGiftMessageSuccess,
    HandleUpdateSubscriptionGiftMessageSuccess,
    ClearSubscriptionGiftMessageSuccess,
    ClearProductAvilabilitySuccess,
    HandleFetchHdEditableCheckSuccess,
    HandleFetchPassportAutoRenewalStatusSuccess,
    HandleUpdatePassportAutoRenewalStatusSuccess,
    HandleSetPassportAutoRenewalStatusSuccess,
    ClearRossOrderDataSuccess,
    ClearRossErrorSuccess,
    //  HandleCsiGuardrailCheckSuccess,
    HandleClearAddressVerificationSuccess,
    HandleValidateAgentKeySuccess,
    HandleValidateCaptchaSuccess,
    HandleClearAgentSubMgmtStateSuccess,
    HandleClearSubscriptionStateSuccess,
    HandleMarketPlaceFormDataSuccess,
    HandleSetAsmCustomerEmailSuccess,
    HandleUpdateSubscriptionFrequencySuccess,
    HandleClearEmailAddressSuccess,
    HandleAddAsmAgentNotesSuccess,
    ClearAsmAgentNotesErrTrackingSuccess,
    ClearAsmAgentNotesStateSuccess,
    HandleFetchAsmAgentNotesSuccess,
    SetThumbsUpForDeliveryImageSuccess,
    HandleCancelPassportMembershipSuccess,
    ClearCancelPassportMembershipStateSuccess,
    SetRecipientOpenStateSuccess,
    HandleClearPartialRefundErrorSuccess,
    HandleClearReplaceResponseSuccess,
    ClearOrderHistoryRossDataSuccess,
    UpdateSubscriptionListSuccess,
    SetRecipientVerifyDataSuccess,
    ClearRecipientVerifyDataSuccess,
    HandleFetchSubscriptionsSuccess,
    HandleSubscriptionSwapSuccess,
    HandleClearSwapSubscriptionSuccess,
    UpdateUserProfileSuccess,
    FetchSubscriptionsByStatusSuccess,
};

export default Action;
