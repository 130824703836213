/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';
import { getBrandCode } from '../../state/ducks/App/ducks/Brand/Brand-Helpers';
import { getAccessTokenSafely } from '../../state/ducks/Member/ducks/Auth/Plugin/auth/auth';

export const fetchEmailSubscribeStatus = async ({ brandId, email }) => {
    const token = await getAccessTokenSafely();
    const wcEnv = { rootUri: '/' };
    const resourcePage = `profile/site/${getBrandCode(brandId)}/user/${email}`;
    let emailSubscribeResponse =  null;
    let isUserSubscribedToMarketingEmails = false;
    const error = {};

    try {
        emailSubscribeResponse = await restClient.getFacade(wcEnv, resourcePage, token);
        const response = emailSubscribeResponse?.data;
        const isSubscribed = response?.retrieveEmailResponse?.retrieveEmailResponseResult?.retrieveEmailResult?.retrieveEmailMessage === 'Active';
        if (isSubscribed) {
            isUserSubscribedToMarketingEmails = true;
            error.message = '';
        }
        return {
            data: { response, isUserSubscribedToMarketingEmails, error },
        };
    } catch (err) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: err,
            message: `fetchEmailSubscribeStatus: Error while fetching email subscription status: ${email}`,
        });
        error.message = err.message;
    }
    return {
        data: { emailSubscribeResponse, isUserSubscribedToMarketingEmails, error },
    };
};

export default {};
