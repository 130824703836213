/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mbpLogger from 'mbp-logger';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons//ErrorOutlineOutlined';
import {
    bool,
    func,
    object,
    string,
} from 'prop-types';
import CloseRounded from './Images/x-close.png';
import ContentCopyIcon from './Images/copy.png';
import { emitCustomerIdentificationEvent, emitCustomTrackingEvent } from '../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { setEmailSubscriptionData, resetEmailOptInSFResponse } from '../../../state/ducks/App/App-Actions';
import { subscribeEmailApi } from '../../../apis/account-apis/subscribeEmailApi';
import useGetAccessTokenSafely from '../../gql/hooks/useGetAccessTokenSafely';
import validateEmailAddress from '../../helpers/validateEmailAddress';

const useStyles = makeStyles((theme) => ({
    headerText: {
        fontSize: '18px !important',
        fontFamily: 'LatoMedium, sans-serif;',
        fontWeight: '600 !important',
        lineHeight: '28px',
        textAlign: 'center',

    },
    dialogTitle: ({ styles }) => ({
        color: styles.formColor || '#5f3c86',
        backgroundColor: styles.formBackgroundColor || '#E5E5E5',
        position: 'relative',
        padding: 'unset',
    }),
    closeIconWrapper: {
        color: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute',
        right: '5%',
        top: '30%',
        cursor: 'pointer',
    },
    dialogContent: ({ styles }) => ({
        backgroundColor: styles.backgroundColor || '#ffffff',
        padding: '20px 24px 7px  24px',
    }),
    checkCircleIcon: {
        fontSize: '4rem',
        color: '#7ed97e',
        display: 'inline-block',
        margin: 'auto',
        fontWeight: '600',
        marginTop: '2rem',
    },
    dialogContentText: {
        color: '#000000',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Lato, sans-serif;',
        fontWeight: 400,
        lineHeight: '20px',
        marginBottom: '20px',
    },
    adjustPadding: {
        padding: '0px 24px 24px !important',
    },
    inputField: {
        width: '100%',
    },
    inputFieldInvalidEmail: {
        position: 'relative',
        '&::before': {
            content: '"Please enter valid email Id"',
            position: 'absolute',
            bottom: '-20px',
            width: '100%',
            height: '25px',
            left: 0,
            paddingLeft: '14px',
            backgroundColor: '#f71449',
            zIndex: '999',
            color: 'white',
            fontSize: '11px',
            fontFamily: 'sans-serif',
            lineHeight: '25px',
            fontWeight: '300',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
            borderRadius: '0px 0px 4px 4px',
        },

    },
    emailFieldText: ({ styles }) => ({
        color: styles.emailFieldColor || '#5f3c86',
        fontWeight: 'bold',
        position: 'relative',
    }),
    inputFieldContainer: {
        display: 'block',
        paddingBottom: '12px',
        '& input': {
            height: '0.3em',
        },
    },
    formControl: {
        width: '100%',
    },
    submitButton: ({ styles, palette }) => ({
        textTransform: 'none',
        color: styles.submitButtonCopyColor || '#ffffff',
        backgroundColor: styles.submitButtonColor || '#5f3c86',
        fontFamily: 'Lato, sans-serif;',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0.04em',
        textAlign: 'left',
        padding: '10px 18px',
        '&:hover': {
            backgroundColor: `${theme.palette.cms?.primary || theme.palette.colorPrimary} !important`,
        },
        '&:disabled': {
            backgroundColor: `${theme.palette.colorDisabled || '#ccc'} !important`,
            color: theme.palette.white || '#fff',
        },
        width: '100%',
    }),
    copyButton: ({ styles }) => ({
        textTransform: 'none',
        color: styles.submitButtonCopyColor || '#ffffff',
        backgroundColor: styles.submitButtonColor || '#5f3c86',
        '&:hover': {
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        width: '100%',
        '& .copyIcon': {
            marginLeft: '5px',
        },
    }),
    bottomContainer: {
        alignItems: 'center',
        display: 'block',
        marginTop: '10px',
        paddingBottom: '15px',
    },
    headerTextFieldWrapper: {
        '& p': {
            color: '#101828',
            fontSize: '18px !important',
            fontFamily: 'Lato, sans-serif;',
            fontWeight: '600 !important',
            lineHeight: '28px',
            textAlign: 'center',
        },
    },
    headerTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '32px 24px 12px 24px',
    },
    adjustHeaderPadding: {
        paddingBottom: '4px',
    },
    outerDialog: {
        width: '100%',
    },
    paper: {
        borderRadius: '12px',
        maxHeight: '300px',
        width: '100%',
        maxWidth: '400px',
        position: 'absolute',
        right: '0',
        top: '95px',
        marginRight: '10px',
        '@media (max-width: 768px)': {
            right: 'unset',
            top: '0',
            margin: 'auto',
        },
    },
    emailRequiredText: ({ styles }) => ({
        color: styles.emailFieldColor || '#5f3c86',
        fontSize: '16px',
        paddingLeft: '3px',
        paddingTop: '2.5px',
        position: 'relative',
    }),
    emailFieldValueValid: {
        '&::after': {
            position: 'absolute',
            right: 'auto',
            marginLeft: '3px',
            bottom: '1px',
            zIndex: '99',
            backgroundColor: '#00c876',
            color: 'white',
            textAlign: 'center',
            content: '"✓"',
            fontSize: '9px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '15px',
            height: '15px',
            animation: 'fadeZoomIn 0.3s ease-in',
            top: '8px',
            borderRadius: '0px 0px 4px 4px',
        },
    },
    buttonContainer: {
        '& #copy-promocode': {
            fontSize: '16px',
            fontWeight: '600',
            textTransform: 'none',
            fontFamily: 'Lato, sans-serif;',
            lineHeight: '24px',
            letterSpacing: '0.04em',
            height: '44px',
            gap: '16px',
            opacity: '0px',
            borderRadius: '3px',
            border: '1px',
            '& img': {
                marginLeft: '5px',
            },
        },
        '& #copySuccess': {
            fontSize: '16px',
            color: '#65388b',
            textAlign: 'center',
            fontFamily: 'Lato, sans-serif;',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '0.04em',
            marginBottom: '20px',
        },
    },
    privacyNoticeLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '5px',
        fontFamily: 'Lato, sans-serif;',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.01em',

        '& a': {
            color: '#000000',
            textUnderlineOffset: '3px',
            textDecoration: 'underline !important',
        },
    },
    dNone: {
        display: 'none',
    },
    errorContainer: {
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '0 0 4px 4px',
        boxShadow: 'none',
        fontFamily: 'Roboto, Helvetica , Arial, sans-serif',
        fontWeight: '400',
        fontSize: '0.890rem',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        display: 'flex',
        padding: '6px 16px',
        color: 'rgb(95, 33, 32)',
        backgroundColor: 'rgb(255 183 183)',
    },
    alertIcon: {
        color: '#d32f2f',
        marginRight: '12px',
        padding: '7px 0',
        display: 'flex',
        fontSize: '22px',
        opacity: '0.9',
    },
    errorOutlined: {
        userSelect: 'none',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fill: 'currentColor',
        flexShrink: '0',
        transition: 'fill 200ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
        fontSize: 'inherit',
    },
    errorText: {
        padding: '8px 0',
        minWidth: 0,
        overflow: 'auto',
    },
}));

const EmailOptInModal = ({
    isOpen,
    closeModal,
    emailOptInData,
    showConfirmation,
    onSubmitCheck,
    handleButtonSubmit,
    handleEmailIdChange,
    validateUserSubmittedEmailId,
    currentEmailId,
    updateEmailOptInData,
    resetEmailOptInData,
    brandId,
}) => {
    const styles = {
        backgroundColor: '#ffffff',
        formColor: '#5f3c86',
        formBackgroundColor: '#ffffff',
        emailFieldColor: '#5f3c86',
        submitButtonColor: '#5f3c86',
        submitButtonCopyColor: '#ffffff',
    };

    const classes = useStyles({ styles });
    const jwtToken = useGetAccessTokenSafely();
    const [errorMsg, setErrorMsg] = useState({ isVisible: false, errorMessage: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document?.getElementById('root')?.removeAttribute('aria-hidden');
    }, []);

    const callSubscribeEmailAPI = () => {
        if (validateUserSubmittedEmailId() && !validateEmailAddress(currentEmailId)) return;
        setLoading(true);
        setErrorMsg({ isVisible: false, errorMessage: '' });
        // subscribe to marketing email api
        subscribeEmailApi({
            email: currentEmailId, brandCode: brandId, token: jwtToken, action: 'SUBSCRIBE',
        }).then((apiResponse) => {
            const { isSubscribed, error } = apiResponse?.data;

            if (error?.message) {
                setErrorMsg({ isVisible: true, errorMessage: error?.message });
            }
            updateEmailOptInData(apiResponse?.data);
            if (isSubscribed) handleButtonSubmit();
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            mbpLogger.logError({
                function: 'callSubscribeEmailAPI.js',
                message: 'Error loading data from subscribe Email API',
                appName: process.env.npm_package_name,
                module: 'Email Opt In',
                jsError: error,
            });
        });
    };

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            callSubscribeEmailAPI();
        }
    };

    const showEmailControl = () => (
        <>
            <Grid item container direction="column" className={classes.textFieldContainer}>
                <Grid item className={classes.inputFieldContainer}>
                    <TextField
                        placeholder="Email"
                        className={
                            onSubmitCheck === true
                                ? `${classes.inputField} ${classes.inputFieldInvalidEmail}`
                                : classes.inputField
                        }
                        onChange={handleEmailIdChange}
                        value={currentEmailId}
                        label=""
                        variant="outlined"
                        id="email-input-field"
                        onFocus={() => { }}
                        onKeyDown={keyPress}
                        InputProps={{
                            disableUnderline: true,
                            inputProps: {
                                'data-element': 'email_field',
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="column" className={classes.bottomContainer}>
                <Grid item className={classes.buttonContainer}>
                    <Button
                        className={classes.submitButton}
                        onClick={() => callSubscribeEmailAPI()}
                        id="email-submit-button"
                        data-testid="email-submit-button"
                        disabled={loading}
                    >
                        {emailOptInData?.cta || 'Receive 20% Off'}
                    </Button>
                </Grid>
                <Grid item className={classes.privacyNoticeLink}>
                    <a href="/About-Us-Privacy-Policy" title="Privacy Notice" target="_blank">Privacy Notice</a>
                </Grid>
            </Grid>
        </>
    );

    const handleCopyToClipboard = () => {
        if (navigator?.clipboard) {
            navigator.clipboard.writeText(emailOptInData?.promotionCode);
            const copyButtonSuccess = document.getElementById('copySuccess');
            const copyButton = document.getElementById('copy-promocode');
            copyButton.classList.add(classes.dNone);
            copyButtonSuccess.classList.remove(classes.dNone);
            setTimeout(() => {
                resetEmailOptInData();
                closeModal();
            }, 4000);
            return true;
        }
        return false;
    };

    const showCopyPromocodeControl = () => (
        <Grid item id="copyPromocodeContainer" className={classes.buttonContainer}>
            <Button
                className={classes.copyButton}
                onClick={() => handleCopyToClipboard(true)}
                id="copy-promocode"
                data-testid="copy-promocode"
            >
                Copy code <img src={ContentCopyIcon} alt="copy-btn-img" className={classes.copyIcon} />
            </Button>
            <p id="copySuccess" className={classes.dNone}>{emailOptInData?.copySuccess}</p>
        </Grid>
    );

    const showErrorControl = () => {
        setTimeout(() => { setErrorMsg({ isVisible: false, errorMessage: '' }); }, 4000);

        return (
            <Grid>
                <div className={classes.errorContainer}>
                    <div className={classes.alertIcon}><ErrorOutlineIcon className={classes.errorOutlined} /></div>
                    <div className={classes.errorText}>{errorMsg?.errorMessage}</div>
                </div>
            </Grid>
        );
    };

    return (
        <>
            <Dialog
                id="optin-email-modal"
                open={isOpen}
                className={`${classes.outerDialog}`}
                classes={{
                    paper: classes.paper,
                }}
                hideBackdrop
                disableEnforceFocus
                disableBackdropClick
                disableScrollLock
                style={{ position: 'fixed', height: 0 }}
                data-testid="optin-email-modal"
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container className={classes.headerContainer}>
                        <Grid item container className={`${showConfirmation ? classes.adjustHeaderPadding : ''} ${classes.headerTextContainer}`}>
                            <Grid item className={classes.headerTextFieldWrapper}>
                                <Typography className={classes.headerText} data-testid="email-modal-form-label">
                                    {emailOptInData?.title || 'Get updates on your email'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                className={classes.closeIconWrapper}
                                onClick={closeModal}
                                id="optin-email-modal-close-button"
                                data-testid="optin-email-modal-close-button"
                            >
                                <img src={CloseRounded} alt="close-btn-img" className={classes.closeIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={`${showConfirmation ? classes.adjustPadding : ''} ${classes.dialogContent}`}>
                    <Grid container direction="column" className={classes.dialogContentContainer}>
                        {showConfirmation ? (<DialogContentText className={classes.dialogContentText}>{emailOptInData?.confirmationMessage}</DialogContentText>)
                            : showEmailControl()}
                    </Grid>
                    {showConfirmation && showCopyPromocodeControl()}
                </DialogContent>
                {errorMsg?.isVisible && showErrorControl()}
            </Dialog>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    callCustomerIdentificationEvent: bindActionCreators(emitCustomerIdentificationEvent, dispatch),
    emitCustomTrackingEvents: bindActionCreators(emitCustomTrackingEvent, dispatch),
    updateEmailOptInData: bindActionCreators(setEmailSubscriptionData, dispatch),
    resetEmailOptInData: bindActionCreators(resetEmailOptInSFResponse, dispatch),
});

EmailOptInModal.propTypes = {
    isOpen: bool.isRequired,
    closeModal: func.isRequired,
    emailOptInData: object,
    showConfirmation: bool.isRequired,
    onSubmitCheck: bool.isRequired,
    handleButtonSubmit: func.isRequired,
    handleEmailIdChange: func.isRequired,
    validateUserSubmittedEmailId: func.isRequired,
    currentEmailId: string.isRequired,
    brandId: string.isRequired,
    updateEmailOptInData: func.isRequired,
    resetEmailOptInData: func.isRequired,
};

EmailOptInModal.defaultProps = {
    emailOptInData: {},
};

export default connect(
    null,
    mapDispatchToProps,
)(EmailOptInModal);
