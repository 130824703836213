/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

const APP_ORIGIN = (typeof window !== 'undefined' && window.location) ? window.location.origin : 'http://localhost';

const getAuth0Config = (brand) => {
    const auth0Config = {
        domain: '',
        audience: '',
        redirectURL: `${APP_ORIGIN}/auth/callback`,
        appSource: mbpUtil.getEnv('APP_AUTH_LOGIN_SOURCE') || '',
        connection: 'T0-Direct-MDM-DB',
    };

    switch (brand) {
        case 'florists':
            auth0Config.domain = mbpUtil.getEnv('APP_FLORISTS_AUTH_DOMAIN');
            auth0Config.audience = mbpUtil.getEnv('APP_FLORISTS_AUTH_AUDIENCE') ? mbpUtil.getEnv('APP_FLORISTS_AUTH_AUDIENCE') : mbpUtil.getEnv('APP_FLORISTS_AUTH_DOMAIN') || 'localhost';
            break;
        case '1800flowers':
            auth0Config.domain = mbpUtil.getEnv('APP_AUTH_DOMAIN');
            auth0Config.audience = mbpUtil.getEnv('APP_AUTH_AUDIENCE') ? mbpUtil.getEnv('APP_AUTH_AUDIENCE') : mbpUtil.getEnv('APP_AUTH_DOMAIN') || 'localhost';
            break;
        default:
            auth0Config.domain = mbpUtil.getEnv('APP_AUTH_DOMAIN');
            auth0Config.audience = mbpUtil.getEnv('APP_AUTH_AUDIENCE') ? mbpUtil.getEnv('APP_AUTH_AUDIENCE') : mbpUtil.getEnv('APP_AUTH_DOMAIN') || 'localhost';
            break;
    }
    return auth0Config;
};
export default getAuth0Config;
