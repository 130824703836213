/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import {
    string, node, object, bool, func, oneOf,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const LinkOrATag = ({
    href,
    id,
    children,
    style,
    name,
    className,
    indexKey,
    dataTest,
    trackingEventAction,
    trackingEventCategory,
    trackingEventLabel,
    isTrackEventEnabled,
    title,
    ariaLabel,
    handleCallback,
    doNotSell,
}) => {
    const dispatch = useDispatch();
    const isExternalLink = href?.includes('/blog') || href?.includes('www') || href?.indexOf('http://') === 0 || href?.indexOf('https://') === 0 || href?.includes(('articles'));
    const handleTrackEvent = (e) => {
        e.stopPropagation();
        if (isTrackEventEnabled && trackingEventLabel) {
            const category = isExternalLink ? 'external-link' : 'internal-link';
            const action = isExternalLink ? href : `https://www.1800flowers.com${href}`;
            dispatch(trackEvent({
                eventCategory: trackingEventCategory || category,
                eventAction: trackingEventAction || action,
                eventLabel: trackingEventLabel || '',
                link_text: typeof children === 'string' ? children : title || href,
                link_url: href || '',
            }));
        }
        if (typeof handleCallback === 'function') {
            handleCallback();
        }
        if (typeof doNotSell === 'function') {
            doNotSell(href);
        }
    };
    if (isExternalLink) {
        return <a style={style} aria-label={ariaLabel} className={className} id={id} href={href} name={name} key={indexKey} data-test={dataTest} onClick={handleTrackEvent} title={title}>{children}</a>;
    }
    return <Link aria-label={ariaLabel} style={style} to={href} id={id} className={className} name={name} key={indexKey} data-test={dataTest} onClick={handleTrackEvent} title={title}>{children}</Link>;
};

LinkOrATag.propTypes = {
    href: string.isRequired,
    id: string,
    children: node.isRequired,
    style: object,
    name: string,
    className: string,
    indexKey: string,
    dataTest: string,
    trackingEventLabel: string,
    isTrackEventEnabled: bool,
    title: string,
    trackingEventAction: string,
    trackingEventCategory: string,
    ariaLabel: string,
    handleCallback: func,
    doNotSell: oneOf([bool, func]),
};

LinkOrATag.defaultProps = {
    id: null,
    style: {},
    name: null,
    className: '',
    indexKey: '',
    dataTest: '',
    trackingEventAction: '',
    trackingEventLabel: '',
    trackingEventCategory: '',
    isTrackEventEnabled: false,
    title: '',
    ariaLabel: '',
    handleCallback: () => {},
    doNotSell: false,
};

export default LinkOrATag;
